import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AppointmentBookingView from '../views/projects/rocket-tickets/appointment/book-appointment/BookAppointmentView.vue';
import RocketTicketView from '@/views/projects/rocket-tickets/RocketTicketView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutView.vue'),
  },
  {
    path: '/rocket-tickets',
    name: 'rocket-tickets',
    component: RocketTicketView,
  },
  {
    path: '/rocket-tickets/store/:storeCuteID/appointment/booking',
    name: 'appointment-booking',
    component: AppointmentBookingView,
    props: true,
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('../views/blogs/BlogsView.vue'),
  },
  {
    path: '/blogs/:article',
    name: "article",
    component: () => import('../views/blogs/ArticleView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue'),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'is-active',
  history: createWebHashHistory(),
  routes,
});

export default router;
