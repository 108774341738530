
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';

export default {
  name: 'RocketTicketView',
  components: {
    RocketTicketsWidget,
    Footer,
    NavBar,
  },
  data() {
    return {
      stores: [
        // {
        //   name: 'Morgan Oasis Nails',
        //   id: 'I3Ina91G01SwYYFDaNiApzxBXkg1'
        // },
        // {
        //   name: 'Perfect Nails Moore',
        //   id: 'G8N9aFUCxfVQNTINxwTppyowkko1'
        // },
        // {
        //   name: 'Dragon Nails Spa',
        //   cute_id: 'dragon-nails-spa'
        // },
        // {
        //   name: 'Nail It',
        //   id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
        // },
        {
          name: 'Test Salon',
          cute_id: 'test-salon'
        },
      ],
    };
  },
};
